export interface IFormatChatHistory {
  id: string;
  originalDate: string;
  chats: IChatHistory[];
}

export interface IFormatChatHistoryByDay {
  id: string;
  updated_at: string;
  chatHistory: IFormatChatHistory[];
}

export interface IChatHistory {
  id: number;
  prompt: string;
  ai_response: string;
  updated_at: string;
  created_at: string;
  user: number;
  version: number;
  ai_model: AiModelType;
}

export interface IChatHistoryResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IChatHistory[];
}

export interface IAIPrompt {
  id: number;
  text: Record<string, string>;
  display: boolean;
  open_ai_model: string;
}

export enum AIModel {
  GPT_4O = "gpt-4o",
  LLAMA_3_1_SONAR_LARGE_128K_ONLINE = "llama-3.1-sonar-large-128k-online",
  SONAR = "sonar"
}

export type AiModelType =
  | AIModel.GPT_4O
  | AIModel.LLAMA_3_1_SONAR_LARGE_128K_ONLINE
  | AIModel.SONAR;

export interface IPrompt {
  order_number: number;
  prompt: string;
}
