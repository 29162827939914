import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";

import { PATH_USER_SUBSCRIPTION } from "router/router.paths";
import {
  MobileConfirmation,
  UnassignedWarningPopup,
  FailedPaymentPopup
} from "components";
import { RootState, useAppDispatch } from "store";
import { getUserDetail } from "store/users";

import { ICurrentUser } from "types/user.model";

interface ContextProps {
  children: ReactNode;
}

type AppContextType = {
  currentUser: ICurrentUser | null;
};

const AppContext = createContext<AppContextType>({
  currentUser: null
});

export function AppProvider({ children }: ContextProps) {
  const [isOpenWarning, setIsOpenWarning] = useState<boolean>(false);
  const [isFailedByPayment, setIsFailedByPayment] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const history = useHistory();
  const location = useLocation();

  const storedCurrentUser = localStorage.getItem("currentUser");

  useEffect(() => {
    if (location.pathname.includes("auth")) return;
    dispatch(getUserDetail());
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (
      currentUser?.is_phone_verified === true &&
      currentUser?.subscription?.unassigned &&
      !currentUser?.subscription?.failed_by_payment &&
      location.pathname !== PATH_USER_SUBSCRIPTION
    ) {
      history.push(PATH_USER_SUBSCRIPTION);
      setIsOpenWarning(true);
    }
  }, [
    currentUser?.is_phone_verified,
    currentUser?.subscription?.failed_by_payment,
    currentUser?.subscription?.unassigned,
    history,
    location.pathname
  ]);

  useEffect(() => {
    if (
      currentUser?.is_phone_verified === true &&
      currentUser?.subscription?.failed_by_payment &&
      location.pathname !== PATH_USER_SUBSCRIPTION
    ) {
      history.push(PATH_USER_SUBSCRIPTION);
      setIsFailedByPayment(true);
    }
  }, [
    currentUser?.is_phone_verified,
    currentUser?.subscription?.failed_by_payment,
    history,
    location.pathname
  ]);

  return (
    <AppContext.Provider
      value={{
        currentUser:
          currentUser ??
          (storedCurrentUser ? JSON.parse(storedCurrentUser) : null)
      }}
    >
      <>
        {children}

        {currentUser?.is_phone_verified === false && (
          <MobileConfirmation isOpen />
        )}

        {currentUser?.is_phone_verified === true && isOpenWarning && (
          <UnassignedWarningPopup
            isOpen={isOpenWarning}
            onClose={() => setIsOpenWarning(false)}
          />
        )}

        {currentUser?.is_phone_verified === true && isFailedByPayment && (
          <FailedPaymentPopup
            isOpen={isFailedByPayment}
            onClose={() => setIsFailedByPayment(false)}
          />
        )}
      </>
    </AppContext.Provider>
  );
}

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};
