import { createApi } from "@reduxjs/toolkit/query/react";

import { authorizedQuery } from "services/query";
import { IParam } from "types/common.model";
import { IIndustry, IIndustryRequest } from "types/industry.model";

export const industriesApi = createApi({
  reducerPath: "industriesApi",
  baseQuery: authorizedQuery,
  tagTypes: ["Industries"],
  endpoints: (build) => ({
    getIndustries: build.query<IIndustry[], IParam>({
      query: (params) => {
        const { q, sector } = params;
        let url = "/api/analyzes/industries/";

        const queryParams: string[] = [];
        if (q) {
          queryParams.push(`q=${encodeURIComponent(q)}`);
        }
        if (sector) {
          queryParams.push(`sector=${encodeURIComponent(sector)}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return url;
      },
      providesTags: ["Industries"]
    }),
    getIndustriesActive: build.query<IIndustry[], void>({
      query: () => "/api/analyzes/industries/active",
      providesTags: ["Industries"]
    }),
    getSectors: build.query<string[], void>({
      query: () => "/api/analyzes/industries/sectors"
    }),
    createIndustry: build.mutation<IIndustry, IIndustryRequest>({
      query: (body) => ({
        url: "/api/analyzes/industries/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Industries"]
    }),
    updateIndustry: build.mutation<
      IIndustry,
      { id: number; body: IIndustryRequest }
    >({
      query: (data) => ({
        url: `/api/analyzes/industries/${data.id}/`,
        method: "PUT",
        body: data.body
      }),
      invalidatesTags: ["Industries"]
    }),
    deleteIndustry: build.mutation<void, number>({
      query: (id) => ({
        url: `/api/analyzes/industries/${id}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["Industries"]
    })
  })
});

export const {
  useGetIndustriesQuery,
  useGetIndustriesActiveQuery,
  useGetSectorsQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation
} = industriesApi;
