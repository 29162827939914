import {
  Action,
  ThunkDispatch,
  configureStore,
  Middleware
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import analysisTypesSlice from "./analysis-types/analysis-types.slice";
import templatesSlice from "./templates.slice";
import { analysesSlice } from "./analysis";
import subscriptionsSlice from "./subscription.slice";
import membersSlice from "./members/members.slice";
import { usersSlice } from "./users";
import { calculatorSlice } from "./calculator";
import { aiAssistantSlice } from "./ai-assistant";

import { rowLogicApi } from "./row-logics";
import { industriesApi } from "./industries";
import { dashboardApi } from "./dashboard";
import { aiPromptsApi } from "./ai-prompts";

const middleware: Middleware[] = [
  rowLogicApi.middleware,
  industriesApi.middleware,
  dashboardApi.middleware,
  aiPromptsApi.middleware
];

export const store = configureStore({
  reducer: {
    template: templatesSlice,
    analysisTypes: analysisTypesSlice,
    analyses: analysesSlice,
    subscriptions: subscriptionsSlice,
    members: membersSlice,
    users: usersSlice,
    calculator: calculatorSlice,
    aiAssistant: aiAssistantSlice,
    [rowLogicApi.reducerPath]: rowLogicApi.reducer,
    [industriesApi.reducerPath]: industriesApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [aiPromptsApi.reducerPath]: aiPromptsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();
