import { IDateTitles } from "./version-data.model";

export interface IAnalysisWorksheet {
  id: number;
  name: string;
  order_number: number;
  video_tutorial: string;
}

export interface IAnalysis {
  id: number;
  name: string;
  publication_info: {
    is_duplicated_from_publish: boolean;
    is_publication_public: boolean;
  };
  created_at?: string;
  author_name: string | null;
  stock_symbol: string;
}

export interface ICreateAnalysisRequest {
  name: string;
  data_source: string;
  stock_symbol: string | null;
  first_projection_year: number | null;
  first_projection_quarter: number | null;
  currency: string;
  unit: number;
  industry: number;
  language: string;
  default_version: {
    name: string;
    version_meta_data: {
      selected_periods: string[];
      projection_years: number | null;
      historical_years: number | null;
    };
  };
  selected_template?: string;
}

export interface IIndustryWithSwot {
  id: number;
  name: string;
  sector: string;
  translations: Record<string, { name: string; sector: string }>;
  strengths: string;
  weaknesses: string;
  opportunities: string;
  threats: string;
}

export interface ICreateAnalysisResponse {
  id: number;
  default_version_id: number;
  created_at: string;
  updated_at: string;
  name: string;
  unit: number;
  currency: string;
  decimals_amount: number;
  data_source: string;
  stock_symbol: string | null;
  stock_company_name: string;
  industry: IIndustryWithSwot;
  countries: string[] | null;
  is_owner: boolean;
  meta_data: {
    [key: string]: {
      child?: string;
      helpers?: string[];
      plug?: string;
    };
  };
  created_by: number;
  updated_by: number;
  template: number;
  worksheets: IAnalysisWorksheet[];
  versions: IVersion[];
  language: "en" | "zh";
}

export interface IVersion {
  id: number;
  name: string;
}

interface IFrequency extends IDateTitles {
  freqstr: string;
}

export interface ILastTitles {
  last_titles: {
    annual: IFrequency;
    quarterly?: IFrequency;
  };
}

export interface INewTitles {
  new_titles: IFrequency[];
}

export interface IAutocompleteResponse {
  company_name: string;
  stock_symbol: string;
  currency: string;
}

export interface TranslatableIndustry {
  name: string;
  sector: string;
}

export interface IAnalysisConfigurationData {
  industries: Record<number, Record<string, TranslatableIndustry>>;
  max_historical_years_amount: {
    annual_with_quarters: number;
    annual: number;
  } | null;
  predefined_industries: number[] | null;
  currency_symbol: string;
}

export interface IDuplicateAnalysisResponse {
  id: number;
  name: string;
  publication_info: {
    is_duplicated_from_publish: boolean;
    is_publication_public: boolean;
  };
  author_name: string | null;
  stock_symbol: string;
}

export interface IIndustry {
  name: string;
  id: number;
}

export interface IAnalysisFirstStep {
  name: string;
  data_source: string;
  stock_symbol: string | null;
  defaultVersionName: string;
  maxHistoricalYearsAvailable: {
    annual_with_quarters: number;
    annual: number;
  } | null;
  currency: string;
  industries: IIndustry[];
  predefinedIndustry: IIndustry[] | null;
}

export interface IAnalysisSecondStep {
  historicalYears: number | null;
  projectionYears: number | null;
  firstProjectionQuarter: number | null;
  firstProjectionYear: number;
  unit: number;
  isQuarters: boolean;
  currency: string;
  selectedPeriods: string[];
  industry: IIndustry;
  language: string;
  availableTemplates?: string[];
}

export interface IAnalysisThirdStep {
  template: string;
}

export type AnalysisInterval = "default" | "yoy" | "pop";

export interface IIndustryAnalysis {
  code: string;
  earnings_share: number;
  exchange: string;
  market_capitalization: number;
  name: string;
  symbol: string;
}

export interface IYearlyFinancialData {
  ebitda: string;
  grossProfit: string;
  incomeBeforeTax: string;
  netIncome: string;
  operatingIncome: string;
  totalRevenue: string;
  year: number;
}

export interface IComparableCompanyData {
  id: number;
  analysis: string;
  country: string;
  currency: string;
  description: string;
  financial_data: {
    yearly: IYearlyFinancialData[];
  };
  industry: string;
  market_capitalization: number;
  stock_exchange: number;
  symbol: string;
  web_url: string;
  name: string;
  enterprise_value: number | null;
  enterprise_ebitda_value: number | null;
  enterprise_revenue_value: number | null;
  eps_estimate_next_year: number | null;
  fiscal_year_end: number | null;
  pe_ratio: number | null;
  peg_ratio: number | null;
  wall_street_target_price: number | null;
}

export interface IIndustryCountry {
  name: string;
  exchange_code: string;
  translation: string;
}

export interface IPublishAnalysisRequest {
  description: string;
  versions: number[];
  publication_username: string;
}

export interface IPublishedAnalysis {
  id: number;
  name: string;
  analysis: {
    id: number;
    name: string;
    stock_symbol: string;
    stock_company_name: string;
    created_by: number;
    language: string;
  };
  description: string;
  publication_date: string;
  versions: [
    {
      id: 0;
      name: string;
    }
  ];
  publication_username: string;
  is_public: boolean;
}

export interface IDuplicatePublicationRequest {
  name: string;
  analysis: {
    name: string;
    stock_symbol: string;
    stock_company_name: string;
    created_by: number;
  };
  description: string;
  publication_date: string;
  publication_username: string;
  is_public: boolean;
}

export interface IFollowing {
  id: number;
  author: {
    id: number;
    public_username: string;
  };
}

export interface IDataSourceRatio {
  current_ratio_ttm: number;
  days_of_inventory_outstanding_ttm: number;
  days_of_payables_outstanding_ttm: number;
  days_of_sales_outstanding_ttm: number;
  debt_equity_ratio_ttm: number;
  dividen_yiel_ttm: number;
  id: number;
  interest_coverage_ttm: number;
  inventory_turnover_ttm: number;
  payables_turnover_ttm: number;
  payout_ratio_ttm: number;
  pe_ratio_ttm: number;
  price_book_value_ratio_ttm: number;
  price_to_free_cash_flows_ratio_ttm: number;
  price_to_sales_ratio_ttm: number;
  quick_ratio_ttm: number;
  receivables_turnover_ttm: number;
  return_on_equity_ttm: number;
  symbol: string;
}

export interface IPriceTarget {
  id: number;
  dcf: number;
  symbol: string;
}

export interface IKeyMetric {
  id: number;
  symbol: string;
  revenue_per_share_ttm: number;
  net_income_per_share_ttm: number;
  operating_cash_flow_per_share_ttm: number;
  free_cash_flow_per_share_ttm: number;
  cash_per_share_ttm: number;
  income_quality_ttm: number;
  capex_to_operating_cash_flow_ttm: number;
  capex_to_revenue_ttm: number;
  capex_per_share_ttm: number;
}

export interface IPiotroskiScore {
  id: number;
  piotroski_score: number;
  symbol: string;
}

export interface ILiveStockPrice {
  symbol: string;
  price: number;
}

export interface IDCFData {
  id: number;
  wacc: number;
  perpetuity_growth_rate: number;
  ebitda_multiply: number;
  version: number;
}

export type DCFDataRequest = Pick<
  IDCFData,
  "wacc" | "perpetuity_growth_rate" | "ebitda_multiply"
>;

export enum ProjectionType {
  AVERAGE_PROJECTION = "average",
  MEDIAN_PROJECTION = "median",
  LAST_PERIOD_PROJECTION = "last_period",
  NOT_USED_PROJECTION = "not_used"
}

export type ProjectionRequest = {
  version: number;
  field_id: string;
  average_projections: number;
  median_projections: number;
  last_period: number;
  last_used_projection: ProjectionType;
};

export type ProjectionResponse = ProjectionRequest & {
  id: number;
};
