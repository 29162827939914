import { Step } from "react-joyride";
import { ProjectionType } from "types/analysis.model";

export const unitTypes: { [key: number]: string } = {
  1: "—",
  1000: "000",
  1000000: "000,000"
};

export const formatList = ["$", "%", "x"];

export const mockDataResponse = {
  IS_100: 100,
  IS_100_helper: 200,
  IS_100_helper_1: 30,
  IS_200: 40
};

export const statementSteps: Step[] = [
  {
    disableBeacon: true,
    target: ".first-row",
    content: "app:assumptions.tutorial-step-1"
  },
  {
    disableBeacon: true,
    target: ".first-cell",
    content: "app:assumptions.tutorial-step-2"
  },
  {
    disableBeacon: true,
    target: ".calculate-btn",
    content: "app:assumptions.tutorial-step-3"
  },
  {
    disableBeacon: true,
    target: ".sheet-sidebar",
    content: "app:assumptions.tutorial-step-4",
    placement: "right"
  }
];

export const PROJECTION_CONSTANTS = [
  {
    value: ProjectionType.AVERAGE_PROJECTION,
    label: "app:calculator.projection-constant-average",
    tooltip: "app:calculator.average-tooltip"
  },
  {
    value: ProjectionType.MEDIAN_PROJECTION,
    label: "app:calculator.projection-constant-median",
    tooltip: "app:calculator.median-tooltip"
  },
  {
    value: ProjectionType.LAST_PERIOD_PROJECTION,
    label: "app:calculator.projection-constant-last-period",
    tooltip: "app:calculator.last-period-tooltip"
  }
];

export const DEFAULT_SETTINGS = {
  currency: "None",
  decimals: 0,
  unit: 1,
  from: "",
  to: "",
  hideAllQuarters: false
};
