import { createApi } from "@reduxjs/toolkit/query/react";

import { authorizedQuery } from "services/query";
import { IAiPrompt, IAiPromptResponse } from "types/ai-prompts";

const AI_PROMPTS_URL = "/api/data-sources/ai-prompt-options/";

export const aiPromptsApi = createApi({
  reducerPath: "aiPromptsApi",
  baseQuery: authorizedQuery,
  tagTypes: ["AIPrompts"],
  endpoints: (build) => ({
    getAIPrompts: build.query<IAiPromptResponse[], void>({
      query: () => AI_PROMPTS_URL,
      transformResponse: (response: IAiPromptResponse[]) => {
        const sortedResponse = [...response].sort(
          (a, b) => a.order_number - b.order_number
        );
        return sortedResponse;
      },
      providesTags: ["AIPrompts"]
    }),
    createAIPrompt: build.mutation<IAiPromptResponse, IAiPrompt>({
      query: (body) => ({
        url: AI_PROMPTS_URL,
        method: "POST",
        body
      }),
      invalidatesTags: ["AIPrompts"]
    }),
    updateAIPrompt: build.mutation<
      IAiPromptResponse,
      { id: number; body: IAiPrompt }
    >({
      query: (data) => ({
        url: `${AI_PROMPTS_URL}${data.id}/`,
        method: "PUT",
        body: data.body
      }),
      invalidatesTags: ["AIPrompts"]
    }),
    reorderAIPrompts: build.mutation<
      IAiPromptResponse,
      { id: number; body: IAiPrompt }
    >({
      query: (data) => ({
        url: `${AI_PROMPTS_URL}${data.id}/`,
        method: "PUT",
        body: data.body
      })
    }),
    deleteAIPrompt: build.mutation<void, number>({
      query: (id) => ({
        url: `${AI_PROMPTS_URL}${id}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["AIPrompts"]
    }),
    displayPrompt: build.mutation<void, { id: number; display: boolean }>({
      query: (data) => ({
        url: `${AI_PROMPTS_URL}${data.id}/`,
        method: "PATCH",
        body: { display: data.display }
      }),
      invalidatesTags: ["AIPrompts"]
    })
  })
});

export const {
  useGetAIPromptsQuery,
  useCreateAIPromptMutation,
  useUpdateAIPromptMutation,
  useDeleteAIPromptMutation,
  useDisplayPromptMutation,
  useReorderAIPromptsMutation
} = aiPromptsApi;
