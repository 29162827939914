import { createApi } from "@reduxjs/toolkit/query/react";

import { authorizedQuery } from "services/query";
import { IMetric } from "types/metric.types";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: authorizedQuery,
  tagTypes: ["Dashboard"],
  endpoints: (build) => ({
    getMetrics: build.query<IMetric, void>({
      query: () => "/api/metrics/main/",
      providesTags: ["Dashboard"]
    })
  })
});

export const { useGetMetricsQuery } = dashboardApi;
