import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import { config, TOKEN_ACCESS, TOKEN_REFRESH } from "constants/config";

import { message } from "helpers/lib";
import i18n from "i18n";
import authServices from "./auth.services";

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem(TOKEN_ACCESS);
    headers.set("Content-Type", "application/json");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  }
});

const baseQueryWithRetry = retry(baseQuery, {
  maxRetries: 2
});

const authorizedQuery = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQueryWithRetry(args, api, extraOptions);
  const error = result.error as any;

  if (error) {
    if (error.status === 401) {
      const refreshToken = localStorage.getItem(TOKEN_REFRESH);

      if (refreshToken) {
        try {
          const newAccessToken = await authServices.refreshAccessToken(
            refreshToken
          );

          localStorage.setItem(TOKEN_ACCESS, newAccessToken.access);

          if (newAccessToken) {
            localStorage.setItem(TOKEN_ACCESS, newAccessToken.access);
            result = await baseQueryWithRetry(args, api, extraOptions);
          } else {
            localStorage.clear();
            setTimeout(() => (window.location.href = "/auth/login"), 1000);
          }
        } catch (err) {
          localStorage.clear();
          setTimeout(() => (window.location.href = "/auth/login"), 1000);
        }
      } else {
        localStorage.clear();
        setTimeout(() => (window.location.href = "/auth/login"), 1000);
      }
    } else if (error.status === 500) {
      message.error("Something is wrong");
      return { error };
    } else if (
      error.data &&
      typeof error.data === "object" &&
      "industry" in error.data
    ) {
      message.error(error.data.industry[0]);
      return { error };
    } else if (
      error.data &&
      typeof error.data === "object" &&
      "non_field_errors" in error.data
    ) {
      message.error(error.data.non_field_errors[0]);
      return { error };
    } else if (
      error.data &&
      typeof error.data === "object" &&
      "code" in error.data
    ) {
      message.error(i18n.t(`error:${error.data.code}`));
      return { error };
    } else if (
      error.data &&
      typeof error.data === "object" &&
      error.data.detail &&
      error.status === 403 &&
      typeof args === "object" &&
      args.url.includes("ai-chat")
    ) {
      return { error: { status: error.status, data: error.data } };
    } else if (
      error.data &&
      typeof error.data === "object" &&
      "name" in error.data &&
      error.data.name?.length > 0
    ) {
      message.error(error.data.name[0]);
      return { error };
    } else if (
      error.data &&
      typeof error.data === "object" &&
      "detail" in error.data
    ) {
      message.error(error.data.detail);
      return { error };
    } else {
      // Other error status. Just throw the error
      return { error };
    }
  }

  return result;
};

const unauthorizedBaseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    return headers;
  }
});

export { authorizedQuery, unauthorizedBaseQuery };
