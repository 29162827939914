import { SVGProps } from "react";

export const DCFIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="evenodd">
      <path
        d="m8.25 14c0-1.7949 1.45507-3.25 3.25-3.25h1.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-1.5c-.9665 0-1.75.7835-1.75 1.75s.7835 1.75 1.75 1.75h1.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-1.5c-1.79493 0-3.25-1.4551-3.25-3.25z"
        fill="currentColor"
      />
      <path
        d="m15.25 13.5c0-1.5188 1.2312-2.75 2.75-2.75h2c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-2c-.6047 0-1.1092.4294-1.225 1h2.225c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-2.25v1.75c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75z"
        fill="currentColor"
      />
      <path
        d="m1.25 11.5c0-.4142.33579-.75.75-.75h2c1.51878 0 2.75 1.2312 2.75 2.75v1c0 1.5188-1.23122 2.75-2.75 2.75h-2c-.41421 0-.75-.3358-.75-.75zm1.5.75v3.5h1.25c.69036 0 1.25-.5596 1.25-1.25v-1c0-.6904-.55964-1.25-1.25-1.25z"
        fill="currentColor"
      />
      <path
        d="m10 2.75c-.69036 0-1.25.55964-1.25 1.25v3.5c0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75v-3.5c0-1.51878 1.23122-2.75 2.75-2.75h7.5c.1989 0 .3897.07902.5303.21967l5.5 5.5c.1407.14065.2197.33142.2197.53033v12.5c0 1.5188-1.2312 2.75-2.75 2.75h-11c-1.51878 0-2.75-1.2312-2.75-2.75v-.2222c0-.4142.33579-.75.75-.75s.75.3358.75.75v.2222c0 .6904.55964 1.25 1.25 1.25h11c.6904 0 1.25-.5596 1.25-1.25v-12.1893l-5.0607-5.06066h-7.1893z"
        fill="currentColor"
      />
      <path
        d="m17 1.25c-.4142 0-.75.33579-.75.75v3.5c0 1.51878 1.2312 2.75 2.75 2.75h4c.3033 0 .5768-.18273.6929-.46299.1161-.28025.0519-.60284-.1626-.81734l-5.5-5.5c-.1406-.14065-.3314-.21967-.5303-.21967z"
        fill="currentColor"
      />
    </g>
  </svg>
);
