import { createApi } from "@reduxjs/toolkit/query/react";

import { authorizedQuery } from "services/query";
import { RootState } from "store";
import { IRowLogic } from "types/row-logic.model";
import { ICalculationTypeRequest } from "types/calculation-type.model";

export const rowLogicApi = createApi({
  reducerPath: "rowLogicApi",
  baseQuery: authorizedQuery,
  tagTypes: ["RowLogics"],
  endpoints: (build) => ({
    getRowLogics: build.query<IRowLogic[], void>({
      query: () => "/api/analyzes/calculation-types/",
      providesTags: ["RowLogics"]
    }),
    createRowLogic: build.mutation<
      ICalculationTypeRequest,
      ICalculationTypeRequest
    >({
      query: (body) => ({
        url: "/api/analyzes/calculation-types/",
        method: "POST",
        body
      }),
      invalidatesTags: ["RowLogics"]
    }),
    updateRowLogic: build.mutation<
      { id: number; body: ICalculationTypeRequest },
      { id: number; body: ICalculationTypeRequest }
    >({
      query: (data) => ({
        url: `/api/analyzes/calculation-types/${data.id}/`,
        method: "PUT",
        body: data.body
      }),
      invalidatesTags: ["RowLogics"]
    }),
    patchRowLogic: build.mutation<
      { id: number; body: ICalculationTypeRequest },
      { id: number; body: ICalculationTypeRequest }
    >({
      query: (data) => ({
        url: `/api/analyzes/calculation-types/${data.id}/`,
        method: "PATCH",
        body: data.body
      }),
      invalidatesTags: ["RowLogics"]
    }),
    deleteRowLogic: build.mutation<number, number>({
      query: (id) => ({
        url: `/api/analyzes/calculation-types/${id}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["RowLogics"]
    })
  })
});

export const selectRowLogics = (state: RootState) =>
  rowLogicApi.endpoints.getRowLogics.select()(state)?.data;

export const {
  useGetRowLogicsQuery,
  useCreateRowLogicMutation,
  useUpdateRowLogicMutation,
  usePatchRowLogicMutation,
  useDeleteRowLogicMutation
} = rowLogicApi;
