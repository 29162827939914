import { ReactNode } from "react";

interface EmptyBlockProps {
  message: string;
  customContent?: ReactNode;
}

export default function EmptyBlock({
  message,
  customContent
}: EmptyBlockProps) {
  return (
    <div className="mb-6 flex min-h-10 w-full items-center justify-center rounded	 border border-dashed border-gray-cb">
      {customContent || <p className="text-gray-cb">{message}</p>}
    </div>
  );
}
