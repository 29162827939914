import { ReactNode, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { CheckGreenIcon } from "icons";

interface GenerateReportLoaderProps {
  isLoading: boolean;
  steps: string[];
  header: ReactNode;
}

export default function GenerateReportLoader({
  isLoading,
  steps,
  header
}: GenerateReportLoaderProps) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    const numberOfSteps = steps.length; // Total number of steps
    const stepDuration = 5000; // Duration for each step in milliseconds

    const interval = setInterval(() => {
      setCurrentStep((prevStep) => {
        if (prevStep === numberOfSteps - 1) {
          clearInterval(interval);
          return prevStep; // Reset t the first step when reaching the last step
        }
        return prevStep + 1; // Move to the next step
      });
    }, stepDuration);

    return () => clearInterval(interval);
  }, [steps.length]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentStep(steps.length - 1);
    }
  }, [isLoading, steps.length]);

  return (
    <Box sx={{ width: "300px", backgroundColor: "#fff", borderRadius: "16px" }}>
      <Box className="border-b border-gray-da" sx={{ padding: "30px 60px" }}>
        <h2 className="text-main-black text-xl text-center relative font-medium">
          {header}
        </h2>
      </Box>
      <div className="py-7.5 flex justify-center">
        <ul className="flex flex-col gap-4">
          {steps.map((step, index) => (
            <li
              key={index}
              className={clsx(
                "text-main-black flex items-center gap-3",
                (currentStep === index || currentStep < index) &&
                  "text-opacity-50"
              )}
            >
              {currentStep === index && (
                <CircularProgress
                  size={24}
                  thickness={4.6}
                  sx={{
                    "&.MuiCircularProgress-root": {
                      color: "#848484"
                    }
                  }}
                />
              )}

              {currentStep > index && <CheckGreenIcon />}
              {currentStep < index && <div className="w-6 h-6" />}

              {t(step)}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
}
